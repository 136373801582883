<template>
  <div id="app">
    <HeaderComp />
    <router-view />
    <FooterComp />
  </div>
</template>

<script>
import HeaderComp from "./components/Header-comp.vue";
import FooterComp from "./components/Footer-comp.vue";

export default {
  components: {
    HeaderComp,
    FooterComp,
  },
};
</script>
