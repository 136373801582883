import { createRouter, createWebHistory } from "vue-router";
import Home from "@/pages/Home-View.vue";
import Downloads from "@/pages/Download-View.vue";
import Wiki from "@/pages/Wiki-View.vue";

const routes = [
  { path: "/", name: "Home", component: Home },
  { path: "/downloads", name: "Downloads", component: Downloads },
  { path: "/wiki", name: "Wiki", component: Wiki },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
