<template>
    <footer>
      <p>&copy; 2024 Loki Work Manager. Alle Rechte vorbehalten.</p>
    </footer>
  </template>
  
  <style scoped>
  footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 10px;
    position: fixed;
    width: 100%;
    bottom: 0;
  }
  </style>
  