<template>
  <div>
    <h1 id="titel1">Update Infos</h1>
    <div v-for="article in articles" :key="article.id" class="wiki-entry">
      <h2>{{ article.title }}</h2>
      <!-- HTML-Inhalt wird hier eingefügt -->
      <div v-html="article.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // Direkt eingebettete Update-Infos mit HTML-Inhalten
      articles: [
        {
          id: 1,
          title: "Version 1.0.1",
          content: `
            <br>
            <strong>Loginfenster:</strong>
            <ul>
              <li>Benutzername ist jetzt ein Fokusfeld</li>
              <li>Login kann jetzt mit Enter bestätigt werden.</li>
            </ul>
            <strong>Admin-Tool:</strong>
            <ul>
              <li>Benutzer können jetzt bearbeitet werden.</li>
            </ul>
            <strong>Hauptmenü:</strong>
            <ul>
              <li>Homepage-Button hinzugefügt</li>
              <li>Automatisches und manuelles Update verfügbar</li>
              <li>Fenstergröße verbessert</li>
            </ul>
            <strong>Notizen:</strong>
            <ul>
              <li>Beim Aktualisieren werden keine Laufnummern vergeben.</li>
            </ul>
          `,
        },
      ],
    };
  },
};
</script>

<style scoped>
/* Stil für jede Update-Info */
.wiki-entry {
  margin-bottom: 30px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

/* Stil für Artikel-Überschriften */
.wiki-entry h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

/* Stil für Listen */
.wiki-entry ul {
  margin: 10px 0;
  padding-left: 20px;
}

.wiki-entry ul li {
  line-height: 1.6;
}

/* Stil für den Haupttitel */
#titel1 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #4a4a4a;
  text-align: center;
}
</style>
