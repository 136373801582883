<template>
    <header>
      <nav>
        <ul>
          <li><router-link to="/">| Home |</router-link></li>
          <li><router-link to="/downloads">Downloads  |</router-link></li>
          <li><router-link to="/wiki">Updates  |</router-link></li>
        </ul>
      </nav>
    </header>
  </template>
  
  <style scoped>
  nav {
    background-color: #333;
    color: white;
    padding: 10px;
  }
  ul {
    list-style: none;
    display: flex;
    gap: 10px;
  }
  li a {
    text-decoration: none;
    color: white;
  }
  </style>
  