<template>
  <div>
    <h1>Willkommen beim Loki Work Manager</h1>
    <p>Hier findest du die aktuellsten Informationen zu unserem Programm.</p>
    <div v-for="info in infos" :key="info.id" class="info-card">
      <h2>{{ info.title }}</h2>
      <p>{{ info.content }}</p>
      <!-- Button wird nur angezeigt, wenn ein Link vorhanden ist -->
      <button
        v-if="info.link"
        class="info-button"
        @click="() => navigateTo(info.link)"
      >
        Mehr erfahren
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      infos: [
        {
          id: 1,
          title: "Neue Version verfügbar",
          content: "Version 1.0.1 jetzt herunterladen!",
          link: "/downloads", // Link zur Download-Seite
        },
        {
          id: 2,
          title: "Feedback",
          content: "Hier geht es zum Feedback",
          link: "https://docs.google.com/spreadsheets/d/1HrMItjy9MzCBPnk7EWvY4cnNj7Is0Vd_YmQFc3Jdek4/edit?usp=sharing", // Google Sheet
        },
        {
          id: 3,
          title: "Wartungsarbeiten",
          content: "Keine Wartungsarbeiten geplant.",
        },
      ],
    };
  },
  methods: {
    navigateTo(link) {
      // Öffnet die URL in einem neuen Tab
      window.open(link, "_blank");
    },
  },
};
</script>

<style scoped>
.info-card {
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.info-button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #626466;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.info-button:hover {
  background-color: #0056b3;
}
</style>
