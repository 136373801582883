<template>
  <div class="downloads-container">
    <h1>Downloads</h1>
    <div v-for="file in files" :key="file.id" class="download-card">
      <div class="download-info">
        <h2>{{ file.name }}</h2>
        <p v-if="file.version">Version: {{ file.version }}</p>
        <p v-if="file.description">{{ file.description }}</p>
      </div>
      <a
        :href="file.link"
        target="_blank"
        class="download-button"
      >
        Herunterladen
      </a>
    </div>
  </div>
</template>

<script>
import API from "@/services/api";

export default {
  name: "DownloadView",
  data() {
    return {
      files: [], // Dynamische Liste der Downloads
    };
  },
  async created() {
    try {
      // Abrufen der Dateien von der API
      const response = await API.get("/downloads");
      this.files = response.data;
    } catch (error) {
      console.error("Fehler beim Abrufen der Downloads:", error);
    }
  },
};
</script>

<style scoped>
.downloads-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  text-align: center;
  color: #333;
}

.download-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  transition: box-shadow 0.3s ease;
}

.download-card:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.download-info {
  max-width: 70%;
}

.download-info h2 {
  margin: 0 0 5px 0;
  font-size: 18px;
  color: #222;
}

.download-info p {
  margin: 2px 0;
  color: #555;
}

.download-button {
  display: inline-block;
  padding: 10px 15px;
  background-color: #626466;
  color: white;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  text-align: center;
}

.download-button:hover {
  background-color: #0056b3;
}
</style>
